<!--支付宝支付-->
<template>
  <div>
    <div ref="alipayWap" v-html="alipay" />
  </div>
</template>

<script>

export default {
  components:{
  },
  data(){
    return{
      alipay:``
    }
  },

  mounted() {
    console.log(this.$route.query)
    this.alipay = this.$route.query.data;


    //xxxxx为请求到的form数据
    document.querySelector("body").innerHTML = this.alipay;
    document.forms[0].submit();

  },

  methods:{

  }

}
</script>

<style lang="scss" scoped>

</style>
